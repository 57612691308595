
.auth-background{
    //background-image: url(https://via.placeholder.com/219x452)
    background-image: url(./../images/burner.jpg);

}
[ngxerrors] {
    color: $danger;
    font-size: 11px;
  }

.more .dropdown-toggle:after {
    content: "";
}
.page-content{
    padding: 16px !important;
}

// .form-control:focus {
//     border-color: #28a745;
//     box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
// }

table > :not(caption) > * > * {
    padding: 0.2rem 0.85rem !important;
   
}
.content-title {
    background-color: #fff !important;
    padding: 10px;
    border: 1px solid #f2f4f9;
    text-transform: uppercase;
  }
.inner-content {
    background-color:white;   
    padding: 20px;   
    border: 1px solid #f2f4f9;;
    
}
.form-group > label{
    text-transform: uppercase;
    
   margin-top: 10px;
    //margin-bottom: 5px;
}
.thumbnail {
    line-height: 20px !important;
    margin-bottom: 5px !important;
    overflow: hidden !important;
    word-break: normal !important;
    border: 1px solid #ddd;
    margin: 2px;
    padding: 5px;
  }
  
  .thumbnail img {
    max-width: 140px;
    max-height: 140px !important;
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
  }
  .table td .btn-group .btn {
    padding: 2px 8px;
 }
 .table td {
   //padding: 2px !important;
   vertical-align: middle !important;
 }
 .table th {
   padding: .4rem !important;
 }



input:read-only {
  background-color:lightgray;
}