@use "./assets/fonts/feather/style.css";

// // 3rd party plugins

@import "~@mdi/font/css/materialdesignicons.min.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~ngx-lightbox/lightbox.css";
//@import 'font-awesome';
//@import "~intl-tel-input/build/css/intlTelInput.css";
@import 'bootstrap-icons';
//@import "~intl-tel-input/build/css/intlTelInput.css";
// @import "~highlight.js/styles/googlecode.css";
// @import '~ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.min.css';
// @import '~ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.min.css';
// @import '~animate.css/animate.min.css';
// @import '~@fullcalendar/common/main.min.css';
// @import '~@fullcalendar/daygrid/main.min.css';
// @import '~@fullcalendar/timegrid/main.min.css';
// @import "~@ng-select/ng-select/themes/default.theme.css";
// @import '~simplemde/dist/simplemde.min.css';

@import 'metismenujs/sass';
// @import '~dropzone/dist/min/dropzone.min.css';
//@import "~quill/dist/quill.snow.css";
// @import '~angular-archwizard/styles/archwizard.css';
// @import '~simple-datatables/dist/style.css';
// @import '~@swimlane/ngx-datatable/index.css';
// @import '~@swimlane/ngx-datatable/themes/bootstrap.css';
// @import '~@swimlane/ngx-datatable/assets/icons.css';

// .iti__flag {background-image: url("../node_modules/intl-tel-input/build/img/flags.png");}

// @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
//   .iti__flag {background-image: url("../node_modules/intl-tel-input/build/img/flags@2x.png");}
// }
// .iti {
//   display: block
// }

// NobleUI styles for LTR
@import "./assets/scss/style";
